import { useStores } from '../../stores/store';
import { Link, useNavigate } from 'react-router-dom';
import HashLoader from 'react-spinners/HashLoader';
import { FaArrowLeft, FaHome, FaPlus } from 'react-icons/fa';
import { config } from "../../config";
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

function PersonalAreaPage(props) {
	const { auth } = useStores();
	const { t, i18n } = useTranslation();

	let navigate = useNavigate();
	const touchEnabled = useRef<boolean>(false);
	const [lessonsData, setLessonsData] = useState(null);

	const refresh = () => {
		auth.getLessonsPersonal().then((res) => {
			setLessonsData(res.data);
		}).catch((error) => {
		}).finally(() => {
		});
	}

	useEffect(() => {
		refresh();

		setTimeout(() => {
			touchEnabled.current = true;
		}, 250)
	}, [])

	const actionLesson = lesson => {
		if (!touchEnabled.current)
			return;

		setTimeout(() => {
			touchEnabled.current = true;
		}, 250)

		touchEnabled.current = false;

		auth.saveLocalLesson(lesson);

		if (config.isComposer()) {
			navigate(`/common/composer/${lesson.id}`);
		} else {
			navigate('/user/interactive-lesson');
		}
	}

	if (lessonsData == null)
		return <div className='grid h-screen place-items-center'><HashLoader color="#EF7d00" /></div>

	return (
		<div className="grid h-screen place-items-center w-full">
			<video className="absolute -z-10 w-full h-full object-fill" autoPlay loop>
				{ config.isMiri() ?
						<source src="/video/background_miri.mp4" type="video/mp4" />
					:
						<source src="/video/background_pinguin.mp4" type="video/mp4" />
				}
			</video>
			<div className="flex flex-col items-center justify-center">
				<div className="bg-primary rounded-t-3xl p-2 text-white text-3xl w-3/4 text-center">{t('personalArea')}</div>
				<div className="bg-white border-2 border-primary rounded-lg py-8 px-6 shadow-2xl">
					<form className="flex items-center">
						{ lessonsData.length == 0 &&
							<div className='w-full'>
								<span className='text-4xl mb-4'>{t('noLessonPresent')}</span>
							</div>
						}
						<div className="grid grid-rows-3 grid-flow-col p-1 gap-x-10 cursor-pointer">
							{ lessonsData.map((lesson, index) => (
								<div onMouseDown={() => actionLesson(lesson)} onTouchStart={() => config.isMiri() ? actionLesson(lesson) : null} key={lesson.id} className="rounded-lg p-1 w-full whitespace-nowrap mb-6 flex flex-row justify-start items-center">
									<span className="bg-primary text-white text-4xl rounded-md px-3 pt-2 text-center mr-2">{index+1}</span>
									<div className='flex flex-col'>
										<span className='text-4xl'>{lesson.title}</span>
										<span className='text-2xl'>{lesson.description}</span>
									</div>
								</div>
							))}
						</div>
					</form>
				</div>
				<div className="flex justify-between items-center gap-1 w-full mx-8">
					<div onMouseDown={() => navigate(`/user/country-selection`)} onTouchStart={() => config.isMiri() ? navigate(`/user/education-level-selection`) : null} className='bg-primary p-8 rounded-lg text-white text-4xl text-center cursor-pointer'>
						<FaArrowLeft />
					</div>
					{ config.isComposer() && <Link className="bg-primary p-8 rounded-lg text-white text-4xl text-center cursor-pointer" to="/common/composer/new"><FaPlus /></Link>}
				</div>
			</div>
		</div>
	)
}

export default PersonalAreaPage;
