import React, { useEffect, useRef, useState } from 'react'
import { useStores } from '../../../stores/store'
import { Link } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa';
import HashLoader from 'react-spinners/HashLoader';
import { Table } from '../../common/table';
import { useTranslation } from 'react-i18next';

function AdminCountriesPage() {
  const { t } = useTranslation();
  const { auth } = useStores();

  const [isDataError, setIsDataError] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [countries, setCountries] = useState(null);

  useEffect(() => {
    setIsDataLoading(true);
    setIsDataError(false);

    auth.getCountries().then((res) => {
      setCountries(res.data);
      setIsDataLoading(false);
    }).catch((error) => {
      setIsDataLoading(false);
      setIsDataError(true);
    });
  }, [])

  const columns = React.useMemo(
      () => [
      {
          Header: t('schools'),
          columns: [
          {
            Header: t('modify'),
            accessor: "id",
            Cell: ({ cell }) => (
              <Link to={
                  `/admin/countries/${cell.row.values.id}`}>
                <FaEdit />
              </Link>
            )
          },
          {
              Header: t('internalName'),
              accessor: 'name',
          },
          {
            Header: t('name'),
            accessor: 'external_name',
        },
          {
            Header: t('priority'),
            accessor: 'priority',
          },
        ],
      },
      ],
      []
  );

  if (isDataLoading)
    return <div className='grid h-screen place-items-center'><HashLoader color="#EF7d00" /></div>

  if (isDataError)
    return <div className='grid h-screen place-items-center'>{t('cannotLoadPage')}</div>

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-row justify-start">
        <span className="text-4xl">{t('folder')}</span>
      </div>
      <div className='flex flex-row justify-end mt-4 space-x-2'>
        <Link className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" to="/admin/countries/new">{t('add')}</Link>
      </div>
      <Table columns={columns} data={countries} />
    </div>
  )
}

export default AdminCountriesPage
