import React from 'react';
import { config } from './config';
import { createRoot } from 'react-dom/client';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { observer } from 'mobx-react-lite';

import './i18n/i18n';

//Style
import 'tailwindcss/tailwind.css';
import './index.css';

//Stores
import { Stores, StoresContext } from './stores/contexts';

//Pages
import LoginPage from './pages/loginPage';
import {Helmet} from "react-helmet";

//Routing
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import reportWebVitals from './reportWebVitals';

import { AuthStore } from './stores/netStore';

import LessonSelectionPage from './pages/user/lessonSelectionPage';
import LessonGroupSelectionPage from './pages/user/lessonGroupSelectionPage';
import EducationLevelSelectionPage from './pages/user/educationLevelSelectionPage';
import PersonalAreaPage from './pages/user/personalAreaPage';
import InteractiveLessonPage from './pages/user/interactiveLessonPage';
import SignupPage from './pages/signupPage';
import AdminCodesPage from './pages/admin/codes/codesPage';
import AdminLessonGroupsPage from './pages/admin/lessons/lessonGroupsPage';
import AdminNewLessonGroupPage from './pages/admin/lessons/newGroup/newGroupPage';
import AdminLessonsPage from './pages/admin/lessons/lessonsPage';
import AdminSchoolsPage from './pages/admin/schools/schoolsPage';
import AdminUsersPage from './pages/admin/users/usersPage';
import AdminUserEditPage from './pages/admin/users/userEditPage';
import MediaSyncPage from './pages/mediaSyncPage';
import AdminCodeEditPage from './pages/admin/codes/codeEditPage';
import StartupPage from './pages/startupPage';
import PasswordRecovery1Page from './pages/passwordRecovery1Page';
import PasswordRecovery2Page from './pages/passwordRecovery2Page';
import AdminCountriesPage from './pages/admin/countries/countriesPage';
import AdminCountryEditPage from './pages/admin/countries/countryEditPage';
import AdminEducationLevelsPage from './pages/admin/education-levels/educationLevelsPage';
import AdminEducationLevelEditPage from './pages/admin/education-levels/educationLevelEditPage';
import AdminCodeGenerationPage from './pages/admin/codes/codeGenerationPage';
import CountrySelectionPage from './pages/user/countrySelectionPage';
import AdminAssociateLessonGroupPage from './pages/admin/lessons/associate/associatePage';
import SchoolDashboardPage from './pages/school/schoolDashboard';
import SchoolCodesPage from './pages/school/codes/codes';
import SchoolUsersPage from './pages/school/users/usersPage';
import SchoolUserEditPage from './pages/school/users/userEditPage';
import PartnerUsersPage from './pages/partner/users/usersPage';
import PartnerCodesPage from './pages/partner/codes/codesPage';
import PartnerSchoolsPage from './pages/partner/schools/schoolsPage';
import PartnerCodeGenerationPage from './pages/partner/codes/codeGenerationPage';
import { Header } from './pages/common/headers/header';
import LessonComposerPage from './pages/common/lessons/lessonComposerPage';
import SchoolRoute from './pages/common/routes/schoolRoute';
import UserRoute from './pages/common/routes/userRoute';
import PartnerRoute from './pages/common/routes/partnerRoute';
import AdminRoute from './pages/common/routes/adminRoute';
import AdminSchoolDetailPage from './pages/admin/schools/schoolDetailPage';
import PartnerSchoolDetailPage from './pages/partner/schools/schoolDetailPage';
import Page404 from './pages/common/page404';
import Page401 from './pages/common/page401';
import ProfessorSignupPage from './pages/professorSignup';

const authStore = new AuthStore();
let stores: Stores = {
	auth: authStore,
};

if (config.env === 'production') {
	console.log = function no_console() { };
}

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: true,
		},
	},
});

export const ContainerRoute = observer(({ children, ...rest }: any) => {
	return <Route {...rest} render={({ location }) =>

			children
  } />;
  });

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
	<React.StrictMode>
		<StoresContext.Provider value={stores}>
			<Helmet>
				<title>{config.isMiri() ? "Aula MIRI" : "Tavolo Pinguin"}</title>
				<link rel="icon" href={config.isMiri() ? "favicon_miri.ico" : "favicon_pinguin.ico"} />
			</Helmet>
			<QueryClientProvider client={queryClient}>
				<Router>
					<Header />

					<Routes>
						{/* Public routes */}
						<Route path='/startup/' element={<StartupPage />} />
						<Route path='/login/' element={<LoginPage />} />
						<Route path='/signup/' element={<SignupPage />} />
						<Route path='/professor-signup/' element={<ProfessorSignupPage />} />
						<Route path='/password-recovery/' element={<PasswordRecovery1Page />} />
						<Route path='/password-reset/' element={<PasswordRecovery2Page />} />
						<Route path='/media-sync/' element={<MediaSyncPage />} />
						<Route path='/page404/' element={<Page404 />} />
						<Route path='/page401/' element={<Page401 />} />

						{/* School routes */}
						<Route path='/school/dashboard/' element={<SchoolRoute Component={SchoolDashboardPage} />} />
						<Route path='/school/users/' element={<SchoolRoute Component={SchoolUsersPage} />} />
						<Route path='/school/users/:id' element={<SchoolRoute Component={SchoolUserEditPage} />} />
						<Route path='/school/codes/' element={<SchoolRoute Component={SchoolCodesPage} />} />

						{/* User routes */}
						<Route path='/user/country-selection/' element={<UserRoute Component={CountrySelectionPage} />} />
						<Route path='/user/education-level-selection/' element={<UserRoute Component={EducationLevelSelectionPage} />} />
						<Route path='/user/lesson-group-selection/' element={<UserRoute Component={LessonGroupSelectionPage} />} />
						<Route path='/user/lesson-selection/' element={<UserRoute Component={LessonSelectionPage} />} />
						<Route path='/user/personal-area/' element={<UserRoute Component={PersonalAreaPage} />} />
						<Route path='/user/interactive-lesson/' element={<UserRoute Component={InteractiveLessonPage} /> } />

						{/* Partner routes */}
						<Route path='/partner/schools/' element={<PartnerRoute Component={PartnerSchoolsPage} />} />
						<Route path='/partner/schools/:id' element={<PartnerRoute Component={PartnerSchoolDetailPage} />} />
						<Route path='/partner/users/' element={<PartnerRoute Component={PartnerUsersPage} />} />
						<Route path='/partner/codes/' element={<PartnerRoute Component={PartnerCodesPage} />} />
						<Route path='/partner/codes/generate/' element={<PartnerRoute Component={PartnerCodeGenerationPage} />} />

						{/* Admin routes */}
						<Route path='/admin/schools/' element={<AdminRoute Component={AdminSchoolsPage} />} />
						<Route path='/admin/schools/:id' element={<AdminRoute Component={AdminSchoolDetailPage} />} />
						<Route path='/admin/users/' element={<AdminRoute Component={AdminUsersPage} />} />
						<Route path='/admin/users/:id' element={<AdminRoute Component={AdminUserEditPage} />} />
						<Route path='/admin/countries/' element={<AdminRoute Component={AdminCountriesPage} />} />
						<Route path='/admin/countries/:id' element={<AdminRoute Component={AdminCountryEditPage} />} />
						<Route path='/admin/education-levels' element={<AdminRoute Component={AdminEducationLevelsPage} />} />
						<Route path='/admin/education-levels/:id' element={<AdminRoute Component={AdminEducationLevelEditPage} />} />

						<Route path='/admin/lessons' element={<AdminRoute Component={AdminLessonsPage} />} />

						<Route path='/admin/lesson-groups' element={<AdminRoute Component={AdminLessonGroupsPage} />} />
						<Route path='/admin/lesson-groups/:id' element={<AdminRoute Component={AdminNewLessonGroupPage} />} />
						<Route path='/admin/lesson-groups/associate/:id' element={<AdminRoute Component={AdminAssociateLessonGroupPage} />} />

						<Route path='/admin/codes/' element={<AdminRoute Component={AdminCodesPage} />} />
						<Route path='/admin/codes/:id' element={<AdminRoute Component={AdminCodeEditPage} />} />
						<Route path='/admin/codes/generate' element={<AdminRoute Component={AdminCodeGenerationPage} />} />

						{/* Common routes */}
						<Route path='/common/composer/:id' element={<LessonComposerPage />} />

						{/* Fallback route */}
						<Route path='*' element={<Navigate to='/login' />} />
					</Routes>
				</Router>
			</QueryClientProvider>
		</StoresContext.Provider>
	</React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
