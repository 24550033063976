import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, useNavigate } from 'react-router-dom';
import HashLoader from "react-spinners/HashLoader";
import { useStores } from "../../../stores/store";
import { useTranslation } from "react-i18next";
import { FaEye, FaEyeSlash } from "react-icons/fa6";

export default function SchoolUserEditPage() {
    const { auth } = useStores();
    const { id } = useParams();
    const { t, i18n } = useTranslation();

    const [isDataError, setIsDataError] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [user, setUser] = useState(null);
    
    const [password, setPassword] = useState<string>("")
    const [confirmPassword, setConfirmPassword] = useState<string>("")
    const [isPasswordShown, setIsPasswordShown] = useState<boolean>(false)

	const [passwordType, setPasswordType] = useState('password');
    const [confirmPasswordType, setConfirmPasswordType] = useState('password');

    const [error, setError] = useState<string>("")
    let navigate = useNavigate();
    const { register, setValue, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = data => postData(data);

    useEffect(() => {
        console.log(id);
        if (id === "new") {
            setIsPasswordShown(true);
        } else {
            setIsDataLoading(true);
            setIsDataError(false);
        
            auth.getUser(id).then((resUser) => {
                setUser(resUser.data);
                setIsDataLoading(false);

            }).catch((error) => {
                setIsDataLoading(false);
                setIsDataError(true);
            });
        }
    }, [])

    useEffect(() => {
        setValue('name', user?.name, { shouldValidate: true })
        setValue('surname', user?.surname, { shouldValidate: true })
        setValue('email', user?.email, { shouldValidate: true })
    }, [user]);

    const postData = async (data) => {
        if (isPasswordShown) {
            if (password.length == 0 || confirmPassword.length == 0) {
                setError(t('passwordNotValid'));
                return;
            }
            if (password != confirmPassword) {
                setError(t('passwordNotValid'));
                return;
            }
        }
        if (id !== "new") {
            await auth.editUser(id, data);
            await auth.editPassword(id, password);
        } else {
            let resp = await auth.addUser(data);
            await auth.editPassword(resp.data.id, password);
        }

        navigate(`/school/users/`);
    }

    const handleTogglePassword = () => {
		if (passwordType === 'password'){
			setPasswordType('text')
		} else {
			setPasswordType('password')
		}
	}

    const handleToggleConfirmPassword = () => {
		if (confirmPasswordType === 'password'){
			setConfirmPasswordType('text')
		} else {
			setConfirmPasswordType('password')
		}
	}

    if (isDataLoading)
        return <div className='grid h-screen place-items-center'><HashLoader color="#EF7d00" /></div>

    if (isDataError)
        return <div className='grid h-screen place-items-center'>{t('cannotLoadPage')}</div>

    return (
        <div className="w-full flex flex-col">
            <div className="flex flex-row justify-center">
                {id == "new" ? <span className="text-4xl">{t('newUser')}</span> : <span className="text-4xl">{t('editUser')}</span>}
            </div>
            <div className="w-full flex flex-row justify-center">
                <form className="w-1/3 bg-white flex flex-col gap-4 p-8 rounded-3xl shadow-xl" onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex flex-col">
                        <label className="block text-gray-700 text-sm font-bold mb-2">{t('name')}</label>
                        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...register("name", { required: true })} />
                        {errors.nameRequired && <span>{t('fieldMandatory')}</span>}
                    </div>
                    <div className="flex flex-col">
                        <label className="block text-gray-700 text-sm font-bold mb-2">{t('surname')}</label>
                        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...register("surname", { required: true })} />
                        {errors.surnameRequired && <span>{t('fieldMandatory')}</span>}
                    </div>
                    <div className="flex flex-col">
                        <label className="block text-gray-700 text-sm font-bold mb-2">{t('email')}</label>
                        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...register("email", { required: true })} />
                        {errors.emailRequired && <span>{t('fieldMandatory')}</span>}
                    </div>
                    { id !== "new" && <button className="border-2 border-primary hover:bg-blue-700 text-primary font-bold py-2 px-4 rounded-full" onClick={(e) => { e.preventDefault(); setIsPasswordShown(!isPasswordShown)}}>{t('modifyPassword')}</button>}
                    { isPasswordShown &&
                        <>
                            <label className="block text-gray-700 text-sm font-bold">{t('password')}</label>
                            <div className="form-group relative flex items-center">
                                <input type={passwordType} onChange={e => setPassword(e.target.value)} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Password" />
                                { 
                                    passwordType == "password" ?
                                        <FaEye className="absolute right-2 top-3 justify-around items-center z-10 cursor-pointer" onClick={handleTogglePassword} />
                                    :
                                        <FaEyeSlash className="absolute right-2 top-3 justify-around items-center z-10 cursor-pointer" onClick={handleTogglePassword} />
                                }
                            </div>
                            <label className="block text-gray-700 text-sm font-bold">{t('confirmPassword')}</label>
                            <div className="form-group relative flex items-center">
                                <input type={confirmPasswordType} onChange={(e) => setConfirmPassword(e.target.value)} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Password" />
							    {
								    confirmPasswordType == "password" ?
									    <FaEye className="absolute right-2 top-3 justify-around items-center z-10 cursor-pointer" onClick={handleToggleConfirmPassword} />
								    :
									    <FaEyeSlash className="absolute right-2 top-3 justify-around items-center z-10 cursor-pointer" onClick={handleToggleConfirmPassword} />
							    }
						    </div>                        </>
                    }
                    { error.length > 0 && <span className="text-red">{error}</span> }
                    <button className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" type="submit">{t('save')}</button>
                    <button className="border-2 border-primary text-primary font-bold py-2 px-4 rounded-full" onClick={() => navigate('/school/users')}>{t('cancel')}</button>
                </form>
            </div>
        </div>
    );
}
