export type Subject = {
    id: number;
    name: string;
    color: string;
}

export type Class = {
    id: number;
    name: string;
    color: string;
}

export type SchoolType = {
    id: number;
    name: string;
    description: string;
    subjects: Subject[];
    classes: Class[];
}

export type Widget = {
    id: string;
    startingPosX: number;
    startingPosY: number;
    posX: number;
    posY: number;
    startingWidth: number;
    startingHeight: number;
    width: number;
    height: number;
    dataRaw: any;
    dataBlob: string;
    dataName: string;
    dataUrl?: string;
    dataType: DataType;
    loadedFromCache?: boolean;
    visible: boolean;
    originalVisibility: boolean;
    type: WidgetType;
    isRight?: boolean,
    isFailed?: boolean;
    ref?: any;
};

export enum WidgetType {
    object,
    canvas,
    background,
    audio,
    objectText,
    objectDestination
}

export enum DataType {
    none,
    image,
    video,
    audio,
    externalVideo,
    text
}

export type Action = {
    id: string;
    widget1: string;
    widget2: string;
    actionType: ActionType;
};

export enum ActionType {
    show,
    match,
    playAudio,
    truefalse,
    showUnique
}
