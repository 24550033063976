import { useState, useRef, useEffect } from "react";
import Popup from 'reactjs-popup';
import { Rnd } from "react-rnd";
import CanvasDraw from "@win11react/react-canvas-draw";
import ProgressBar from "@ramonak/react-progress-bar";
import { config } from "../../../config";
import {
    FaPlay,
    FaPause,
    FaBrush,
    FaBackward,
    FaForward,
    FaTrash,
    FaStop,
    FaSave,
} from 'react-icons/fa';
import 'reactjs-popup/dist/index.css';
import { useStores } from "../../../stores/store";
import { useParams } from "react-router";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Action, ActionType, DataType, Widget, WidgetType } from "../../../models/models";
import HashLoader from "react-spinners/HashLoader";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../../models/db";
import { useTranslation } from "react-i18next";
import { halve } from "../../../utils/utils";
import { FaCirclePlus } from "react-icons/fa6";

enum ComposerMode {
    edit,
    view,
    audioList
}

type ControlProps = {
    onReset: any;
    onPlay: any;
    onPause: any;
    onBack: any;
    onForward: any;
};

function ControlBar(props: ControlProps) {
    const { t } = useTranslation();
    const { auth } = useStores();

    return (
        <div className="absolute top-2 right-2 bg-primary rounded-3xl flex flex-col justify-center items-start p-2 text-white">
            <div onClick={() => props.onReset()} className="p-2 flex items-center rounded-md duration-300 cursor-pointer hover:bg-blue-600 text-white">
                <FaBrush />
                <span className="text-[15px] ml-4 text-gray-200 font-bold">{t('reset')}</span>
            </div>
            <div onClick={() => props.onPlay()} className="p-2 flex items-center rounded-md duration-300 cursor-pointer hover:bg-blue-600 text-white">
                <FaPlay />
                <span className="text-[15px] ml-4 text-gray-200 font-bold">{t('play')}</span>
            </div>
            <div onClick={() => props.onPause()} className="p-2 flex items-center rounded-md duration-300 cursor-pointer hover:bg-blue-600 text-white">
                <FaPause />
                <span className="text-[15px] ml-4 text-gray-200 font-bold">{t('pause')}</span>
            </div>
            <div className="w-full p-2 flex flex-row justify-evenly rounded-md duration-300 cursor-pointer hover:bg-blue-600 text-white">
                <FaBackward onClick={() => props.onBack()} />
                <FaForward onClick={() => props.onForward()} />
            </div>
        </div>
    );
}

export default function LessonComposerPage() {
    const { t } = useTranslation();

    const { auth } = useStores();
    const params = useParams();
    const { register, handleSubmit, setValue, formState: { errors, isSubmitted } } = useForm();
    const { register: registerDim, handleSubmit: handleSubmitDim, reset: resetDim, setValue: setValueDim } = useForm();
    const { register: registerRatio, handleSubmit: handleSubmitRatio, reset: resetRatio, setValue: setValueRatio } = useForm();
    const { register: registerExtVid, handleSubmit: handleSubmitExtVid, reset: resetExtVid, setValue: setValueExtVid } = useForm();
    const { register: registerText, handleSubmit: handleSubmitText, reset: resetText, setValue: setValueText } = useForm();
    const { register: registerTitle, handleSubmit: handleSubmitTitle, reset: resetTitle, setValue: setValueTitle } = useForm();

    const [countries, setCountries] = useState([]);
    const [screenSize, setScreenSize] = useState([0, 0]);
    const [screenRatioStr, setScreenRatioStr] = useState("48_9");
    const [screenRatio, setScreenRatio] = useState(48/9);
    const [widgets, setWidgets] = useState<Widget[]>([]);
    const [audios, setAudios] = useState<Widget[]>([]);
    const canvasRefs = useRef<any[]>([]);
    const [actions, setActions] = useState<Action[]>([]);
    const [background, setBackground] = useState<Widget>(null);
    const [addActionShown, setAddActionShown] = useState<boolean>(false);
    const [addExternalVideoShown, setAddExternalVideoShown] = useState<boolean>(false);
    const [changeDimensionShown, setChangeDimensionShown] = useState<boolean>(false);
    const [changeTitleShown, setChangeTitleShown] = useState<boolean>(false);
    const [videoWarningShown, setVideoWarningShown] = useState<boolean>(false);
    const [saveShown, setSaveShown] = useState<boolean>(false);
    const [addTextShown, setAddTextShown] = useState<boolean>(false);
    const [progress, setProgress] = useState<number>(0);
    const [actionSelected, setActionSelected] = useState<ActionType>();
    const [actionOngoing, setActionOngoing] = useState<boolean>(false);
    const [composerMode, setComposerMode] = useState<ComposerMode>(ComposerMode.edit);
    const [firstElement, setFirstElement] = useState<Widget>();
    const [firstActionElement, setFirstActionElement] = useState<Widget>();
    const [statusText, setStatusText] = useState<string>("");
    const [brushColor, setBrushColor] = useState<string>("#FF0000");

    const [title, setTitle] = useState<string>("");
    const [instructions, setInstructions] = useState<string>("");

    const [contextMenuVisible, setContextMenuVisible] = useState<boolean>(false);
    const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });
    const [contextMenuObject, setContextMenuObject] = useState<Widget>(null);

    const [isLoading, setIsLoading] = useState<boolean>(params.id !== "new");
    const [isError, setIsError] = useState<boolean>(false);
    const [showSelectRatio, setShowSelectRatio] = useState<boolean>(params.id === "new");

    const downloadedMediaItems = useLiveQuery(
        () => db.videoList.toArray()
    );

    const selectBackgroundRef = useRef(null);
    const selectObjectRef = useRef(null);
    const selectAudioRef = useRef(null);
    const screenRef = useRef(null);
    const vidRef = useRef(null);

    const currentAudio = useRef<any>(null);
    let myAudio;

    useEffect(() => {
        getCountries();

        if (params.id != "new") {
            getLesson();
        }
    }, []);

    const getCountries = () => {
        auth.getCountries().then(res => {
            setCountries(res.data);
        }).catch((error) => {
            setIsError(true);
        });
    }

    const getLesson = () => {
        auth.getLesson(params.id).then(res => {
            setValue('name', res.data?.name, { shouldValidate: true })
            setValue('title', res.data?.title, { shouldValidate: true })
            setValue('description', res.data?.description, { shouldValidate: true })
            setValue('fk_country', res.data?.fk_country, { shouldValidate: true })
            setValue('is_completed', res.data?.is_completed, { shouldValidate: true })
            parse(res.data.content);
            return res.data.content;
        }).catch((error) => {
            setIsError(true);
        });
    }

    useEffect(() => {
        if (downloadedMediaItems !== null && downloadedMediaItems !== undefined) {
            if (params.id !== "new")
                getLesson();
        }
    }, [downloadedMediaItems]);

    useEffect(() => {
        function updateSize() {
            if (background != null) { 
                background.width = window.innerWidth;
                background.height = window.innerWidth / (screenRatio);
                repositionateWidgets();
            }
        }
        window.addEventListener('resize', updateSize);
        return () => window.removeEventListener('resize', updateSize);
    }, [widgets]);

    const isVideo = (e) => {
        let name = e.name.toLowerCase();

        return name.endsWith("mp4") ||
            name.endsWith("mov") ||
            name.endsWith("avi");
    }

    const onSelectBackground = e => {
        if (!e.target.files || e.target.files.length === 0) {
            return
        }

        if (isVideo(e.target.files[0]) && config.isComposer()) {
            setVideoWarningShown(true);
            return;
        }

        let dataType = DataType.image;
        if (isVideo(e.target.files[0]))
            dataType = DataType.video;

        let widget: Widget = {
            id: Date.now().toString(),
            startingPosX: 0,
            startingPosY: 0,
            posX: 0,
            posY: 0,
            startingWidth: screenRef.current.offsetWidth,
            startingHeight: screenRef.current.offsetHeight,
            width: screenRef.current.offsetWidth,
            height: screenRef.current.offsetHeight,
            visible: true,
            originalVisibility: true,
            type: WidgetType.background,
            dataType: dataType,
            dataName: e.target.files[0].name,
            dataRaw: e.target.files[0],
            dataBlob: URL.createObjectURL(e.target.files[0]),
        };

        setBackground(widget);
        e.target.value = null;
    }

    const onSelectObject = async (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            return
        }

        let defaultWidth = 5760;

        if (screenRatioStr == "16_9")
            defaultWidth = 1920;

        let localWidgets = [...widgets];

        for (let i = 0; i < e.target.files.length; i++) {
            if (isVideo(e.target.files[i]) && config.isComposer()) {
                setVideoWarningShown(true);
                return;
            }
        }

        for (let i = 0; i < e.target.files.length; i++) {
            let dataType = DataType.image;
            if (isVideo(e.target.files[i]))
                dataType = DataType.video;

            let img = new Image();
            img.src = URL.createObjectURL(e.target.files[i]);
            await img.decode();

            let increaseRatio = 2;

            let widget: Widget = {
                id: Date.now().toString(),
                startingPosX: 0,
                startingPosY: 0,
                posX: 0,
                posY: 0,
                // defaultWidth : img.width = screenSize[0] : x
                startingWidth: window.innerWidth * img.width / defaultWidth,
                startingHeight: (window.innerWidth / (screenRatio)) * img.height / (defaultWidth / (screenRatio)),
                width: window.innerWidth * img.width / defaultWidth,
                height: (window.innerWidth / (screenRatio)) * img.height / (defaultWidth / (screenRatio)),
                visible: true,
                originalVisibility: true,
                dataName: e.target.files[i].name,
                dataRaw: e.target.files[i],
                dataBlob: URL.createObjectURL(e.target.files[i]),
                dataType: dataType,
                type: WidgetType.object
            };
            widget.width += (img.width * increaseRatio) / 100;
            widget.height += (img.height * increaseRatio) / 100;

            localWidgets.push(widget);
        }
        setWidgets(localWidgets);
        e.target.value = null;
    }

    const onSelectAudio = async (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            return
        }

        let localAudios = [...audios];

        for (let i = 0; i < e.target.files.length; i++) {
            let widget: Widget = {
                id: Date.now().toString(),
                startingPosX: 0,
                startingPosY: 0,
                posX: 0,
                posY: 0,
                startingWidth: 0,
                startingHeight: 0,
                width: 0,
                height: 0,
                visible: true,
                originalVisibility: true,
                dataName: e.target.files[i].name,
                dataRaw: e.target.files[i],
                dataBlob: URL.createObjectURL(e.target.files[i]),
                dataType: DataType.audio,
                type: WidgetType.audio
            };

            localAudios.push(widget);
        }
        setAudios(localAudios);
        e.target.value = null;

        switchMode(ComposerMode.audioList);
    }

    const lookupWidget = (list, id) => {
        return list.find((element) => element.id === id);
    }

    const onDragStop = (id, d) => {
        let object = lookupWidget(widgets, id);
        object.posX = d.x;
        object.posY = d.y;

        setWidgets(widgets.map(item =>
            item.id === object.id ? {
                ...item,
                posX: object.posX,
                posY: object.posY,
                startingPosX: object.posX,
                startingPosY: object.posY
            }
                : item));
    }

    const onResizeStop = (id, d) => {
        let object = lookupWidget(widgets, id);
        object.width = +d.width.replace('px', '');
        object.height = +d.height.replace('px', '');

        setWidgets(widgets.map(item =>
            item.id === object.id
                ? { ...item, width: object.width, height: object.height }
                : item));
    }

    const clicked = (id: string) => {
        let object = lookupWidget(widgets, id);

        if (!actionOngoing || object.type == WidgetType.canvas) {
            setStatusText(
                "x: " + Math.round(object.posX) + " - y: " + Math.round(object.posY) + ` - ${t('height')}: ` + Math.round(object.width) + ` - ${t('width')}: ` + Math.round(object.height));
            return;
        }

        if (!firstElement) {
            setFirstElement(object);

            if (actionSelected === ActionType.show) {
                setStatusText(t('selectBaloon'));
            } else if (actionSelected === ActionType.showUnique) {
                setStatusText(t('selectBaloon'));
            } else if (actionSelected === ActionType.match) {
                setStatusText(t('selectDestination'));
            } else if (actionSelected === ActionType.playAudio) {
                setStatusText(t('selectAudio'));
                switchMode(ComposerMode.audioList);
            } else if (actionSelected === ActionType.truefalse) {
                setStatusText(t('selectFalse'));
            }

            return;
        }

        let action: Action = {
            id: Date.now().toString(),
            widget1: firstElement.id,
            widget2: id,
            actionType: actionSelected
        }

        if (actionSelected === ActionType.show || actionSelected === ActionType.showUnique) {
            lookupWidget(widgets, id).originalVisibility = false;
            lookupWidget(widgets, id).visible = false;
        }

        setActions([...actions, action]);

        setFirstElement(null);
        setStatusText(t('actionCreated'));
        setActionOngoing(false);
    }

    const clickedAudio = (id: string) => {
        if (!firstElement) {
            return;
        }

        let action: Action = {
            id: Date.now().toString(),
            widget1: firstElement.id,
            widget2: id,
            actionType: ActionType.playAudio
        }

        setActions([...actions, action]);

        setFirstElement(null);
        setStatusText(t('actionCreated'));
        setActionOngoing(false);
        switchMode(ComposerMode.edit);
    }

    const playAudio = (audio) => {
        if (audio.dataBlob != null) {
            myAudio = new Audio(audio.dataBlob);
            currentAudio.current = myAudio;
        } else {
            myAudio = new Audio(audio.dataUrl);
            currentAudio.current = myAudio;
        }

        myAudio.addEventListener("canplaythrough", function() {
            myAudio.play();
        })
    }

    const stopAudio = () => {
        if (currentAudio.current)
            currentAudio.current.pause();
    }

    const performClickAction = (widgetId: string) => {
        /*
        if (!touchEnabled.current)
            return;

        setTimeout(() => {
            touchEnabled.current = true;
        }, 600)

        touchEnabled.current = false;
        */

        let localWidgets = [...widgets];
        let isActioned: boolean = false;

        for (let i = 0; i < actions.length; i++) {
            let action = actions[i];

            if (action.actionType === ActionType.playAudio) {
                if (widgetId !== action.widget1)
                    continue;

                let audioWidget = lookupWidget(audios, action.widget2);

                playAudio(audioWidget);
            } else if (action.actionType === ActionType.show) {
                if (widgetId !== action.widget1)
                    continue;

                let widget2 = lookupWidget(localWidgets, action.widget2);
                widget2.visible = !widget2.visible;
            } else if (action.actionType === ActionType.showUnique) {
                if (widgetId !== action.widget1)
                    continue;

                let widget2 = lookupWidget(localWidgets, action.widget2);
                widget2.visible = !widget2.visible;

                if (widget2.visible == true) {
                    /* hide all other widgets */
                    for (let j = 0; j < actions.length; j++) {

                        if (actions[j].actionType === ActionType.show || 
                            actions[j].actionType === ActionType.showUnique) {

                                if (actions[j].widget2 === widget2.id)
                                    continue;

                                let myWidget = lookupWidget(localWidgets, actions[j].widget2);
                                myWidget.visible = false;
                        }
                    }
                }
            } else if (action.actionType === ActionType.match) {
                let widget1 = lookupWidget(localWidgets, action.widget1);
                let widget2 = lookupWidget(localWidgets, action.widget2);

                if (widgetId === action.widget1) {
                    if (firstActionElement) {
                        setFirstActionElement(null);
                        continue;
                    }

                    setFirstActionElement(widget1);
                }

                if (widgetId === action.widget2) {
                    if (!firstActionElement || firstActionElement.id !== action.widget1)
                        continue;

                    widget1.posX = widget2.posX;
                    widget1.posY = widget2.posY;
                    widget1.width = widget2.width;
                    widget1.height = widget2.height;

                    setFirstActionElement(null);

                    widget1.ref.updatePosition({
                        x: widget1.posX,
                        y: widget1.posY
                    });

                    widget1.ref.updateSize({
                        width: widget1.width,
                        height: widget1.height
                    })

                    /* hack to make target widget invisible
                    widget2.ref.updateSize({
                        width: 0,
                        height: 0
                    });
                    */
                    widget2.visible = false;

                    isActioned = true;
                }
            } else if (action.actionType === ActionType.truefalse) {
                if (widgetId === action.widget1 || widgetId === action.widget2) {
                    let myWidget = lookupWidget(localWidgets, widgetId);
                    /* action true, green overlay */
                    if (widgetId === action.widget1) {
                        myWidget.isRight = true;
                    }
                    /* action false, red overlay */
                    if (widgetId === action.widget2) {
                        myWidget.isFailed = true;
                    }
                }
                window.setTimeout(() => clearAnimations(), 1000);
                isActioned = true;
            }
        }
        if (firstActionElement != null && isActioned == false) {
            let myWidget1 = lookupWidget(localWidgets, widgetId);
            myWidget1.isFailed = true;

            window.setTimeout(() => clearAnimations(), 1000);

            setFirstActionElement(null);

        }

        setWidgets(localWidgets);
    }

    const clearAnimations = () => {
        let localWidgets = [...widgets];

        for (let i = 0; i < localWidgets.length; i++) {
            localWidgets[i].isFailed = false;
            localWidgets[i].isRight = false;
        }

        setWidgets(localWidgets);
    }

    const addBackground = () => {
        if (selectBackgroundRef && selectBackgroundRef.current)
            selectBackgroundRef.current.click();
    }

    const selectAction = (action: ActionType) => {
        setFirstElement(null);
        setAddActionShown(false);
        setActionOngoing(true);

        setActionSelected(action);

        if (action === ActionType.show) {
            setStatusText(t('selectButton'));
        } else if (action === ActionType.match) {
            setStatusText(t('selectDragElement'));
        } else if (action === ActionType.playAudio) {
            setStatusText(t('selectButton'));
        } else if (action === ActionType.truefalse) {
            setStatusText(t('selectTrue'));
        } else if (action === ActionType.showUnique) {
            setStatusText(t('selectButton'));
        }
    }

    const addObject = () => {
        if (selectObjectRef && selectObjectRef.current)
            selectObjectRef.current.click();
    }

    const addText = () => {
        setAddTextShown(true);
    }

    const addTitle = () => {
        setChangeTitleShown(true);
    }

    const addCanvas = () => {
        let widget: Widget = {
            id: Date.now().toString(),
            startingPosX: 0,
            startingPosY: 0,
            posX: 0,
            posY: 0,
            startingWidth: 150,
            startingHeight: 150,
            width: 150,
            height: 150,
            originalVisibility: true,
            visible: true,
            dataRaw: null,
            dataBlob: null,
            dataName: null,
            dataType: DataType.none,
            type: WidgetType.canvas
        };

        setWidgets([...widgets, widget]);
    }

    const addExternalVideo = (data) => {

        let body = data['body'];
        let index = -1;

        index = body.indexOf("https://www.youtube");

        if (index != -1) {
            body = body.substring(index, body.length-1);
            index = body.indexOf("\"");
            body = body.substring(0, index);
        }

        index = body.indexOf("https://player.vimeo");

        if (index != -1) {
            body = body.substring(index, body.length-1);
            index = body.indexOf("\"");
            body = body.substring(0, index);
        }

        index = body.indexOf("https://view.genially.com");

        if (index != -1) {
            body = body.substring(index, body.length-1);
            index = body.indexOf("\"");
            body = body.substring(0, index);
        }

        index = body.indexOf("https://www.canva.com");

        if (index != -1) {
            body = body.substring(index, body.length-1);
            index = body.indexOf("\"");
            body = body.substring(0, index);
        }

        index = body.indexOf("https://www.thinglink.com");

        if (index != -1) {
            body = body.substring(index, body.length-1);
            index = body.indexOf("\"");
            body = body.substring(0, index);
        }

        let widget: Widget = {
            id: Date.now().toString(),
            startingPosX: 0,
            startingPosY: 0,
            posX: 0,
            posY: 0,
            startingWidth: screenRef.current.offsetWidth,
            startingHeight: screenRef.current.offsetHeight,
            width: screenRef.current.offsetWidth,
            height: screenRef.current.offsetHeight,
            visible: true,
            originalVisibility: true,
            type: WidgetType.background,
            dataType: DataType.externalVideo,
            dataName: "",
            dataRaw: body,
            dataBlob: body,
            dataUrl: body
        };

        resetExtVid();
        setBackground(widget);
        setWidgets([]);
        setActions([]);
        setAudios([]);
        setAddExternalVideoShown(false)
    }

    const doAddText = (data) => {
        setAddTextShown(false)
        resetText();

        let body = data['body'];

        let widget: Widget = {
            id: Date.now().toString(),
            startingPosX: 0,
            startingPosY: 0,
            posX: 0,
            posY: 0,
            startingWidth: 100,
            startingHeight: 100,
            width: 100,
            height: 100,
            visible: true,
            originalVisibility: true,
            type: WidgetType.objectText,
            dataType: DataType.text,
            dataName: "",
            dataRaw: body,
            dataBlob: body,
            dataUrl: body
        };

        setWidgets([...widgets, widget]);
    }

    const addAudio = () => {
        if (selectAudioRef && selectAudioRef.current) {
            selectAudioRef.current.click();
        }
    }

    const deleteAudio = (audio) => {
        let localAudios = audios.filter(obj => obj.id !== audio.id);
        setAudios(localAudios);
    }

    const resetPositions = () => {
        let localWidgets = [...widgets];

        localWidgets.forEach((widget) => {
            widget.posX = widget.startingPosX;
            widget.posY = widget.startingPosY;


            widget.ref.updatePosition({
                x: widget.startingPosX,
                y: widget.startingPosY
            });

            /*
            widget.ref.updateSize({
                width: widget.startingWidth,
                height: widget.startingHeight
            });
            */

            widget.visible = widget.originalVisibility;
        });

        setWidgets(localWidgets);

        canvasRefs.current?.forEach((canvas) => {
            try {
                canvas.eraseAll();
            } catch (e) {
            }
        });
    }

    const repositionateWidgets = () => {
        let localWidgets = [...widgets];

        for (let index = 0; index < localWidgets.length; index++) {
            let widget = localWidgets[index];

            let currentWidth = screenSize[0] == 0 ? window.innerWidth : screenSize[0];
            let currentHeight = screenSize[1] == 0 ? window.innerWidth / (screenRatio) : screenSize[1];

            let newScreenWidth = window.innerWidth;
            let newScreenHeight = window.innerWidth / (screenRatio);

            if (currentWidth !== newScreenWidth || currentHeight !== newScreenHeight) {
                widget.startingPosX = (newScreenWidth * widget.startingPosX) / currentWidth;
                widget.startingPosY = (newScreenHeight * widget.startingPosY) / currentHeight;

                widget.posX = (newScreenWidth * widget.posX) / currentWidth;

                widget.posY = (newScreenHeight * widget.posY) / currentHeight;

                widget.width = (newScreenWidth * widget.width) / currentWidth;
                widget.height = (newScreenHeight * widget.height) / currentHeight;
            }

            widget.ref.updatePosition({
                x: widget.posX,
                y: widget.posY
            });

            widget.ref.updateSize({
                width: widget.width,
                height: widget.height
            })
        }

        setWidgets(localWidgets);
        setScreenSize([window.innerWidth, window.innerWidth / (screenRatio)]);
    }

    const changeView = () => {
        resetPositions();

        if (composerMode == ComposerMode.edit)
            setComposerMode(ComposerMode.view);
        else
            setComposerMode(ComposerMode.edit);
    }

    const switchMode = (mode: ComposerMode) => {
        setComposerMode(mode);
    }

    // backgroundDimension : parsedDimension = currentScreenDimension : x
    const scaleDown = (originalBackgroundDimension: number, currentDimension: number, screenDimension: number) => {
        return (currentDimension * screenDimension) / originalBackgroundDimension;
    }

    const parse =  async (jsonBody) => {
        let localWidgets = [];
        let localAudios = [];
        let localActions = [];

        jsonBody = JSON.parse(jsonBody)['data'];

        let parsedRatio = jsonBody['ratio'];
        let currentScreenRatio = 0;

        if (parsedRatio == undefined || parsedRatio == "48_9") {
            setScreenRatioStr("48_9");
            setScreenRatio(48/9);
            currentScreenRatio = 48/9;
        } else {
            setScreenRatioStr("16_9");
            setScreenRatio(16/9);
            currentScreenRatio = 16/9;
        }

        let parsedT = jsonBody['title'];
        if (parsedT != null && parsedT != undefined) {
            setTitle(jsonBody['title'])
        }

        let parsedI = jsonBody['instructions'];
        if (parsedI != null && parsedI != undefined) {
            setInstructions(jsonBody['instructions'])
        }

        let parsedB = jsonBody['background'];

        let originalBackgroundWidth = parsedB.width;
        let originalBackgroundHeight = originalBackgroundWidth / (currentScreenRatio);
        let downloadedBlob = downloadedMediaItems?.filter((item) => item.url == parsedB.dataUrl);

        let widgetB: Widget = {
            id: parsedB.id,
            startingPosX: parsedB.startingPosX,
            startingPosY: parsedB.startingPosY,
            posX: parsedB.posX,
            posY: parsedB.posY,
            startingWidth: window.innerWidth,
            startingHeight: window.innerWidth / (currentScreenRatio),
            width: window.innerWidth,
            height: window.innerWidth / (currentScreenRatio),
            visible: parsedB.visible,
            originalVisibility: parsedB.visible,
            dataUrl: (parsedB.dataUrl && parsedB.dataUrl.startsWith("http")) ? parsedB.dataUrl : config.mediaEndpoint + parsedB.dataUrl,
            dataRaw: parsedB.dataRaw,
            dataBlob: (downloadedBlob != null && downloadedBlob.length > 0) ? URL.createObjectURL(downloadedBlob[0].data) : parsedB.dataBlob,
            dataName: parsedB.dataName,
            dataType: parsedB.dataType,
            loadedFromCache: downloadedBlob != null && downloadedBlob.length > 0,
            type: parsedB.type
        };
        setBackground(widgetB);

        for (let i = 0; i < jsonBody['widgets'].length; i++) {
            let parsedW = jsonBody['widgets'][i];
            let downloadedBlob = downloadedMediaItems?.filter((item) => item.url == parsedW.dataUrl);

            let newHeight = parsedW.height;

            try {
                let img = new Image();
                img.src = parsedW.dataUrl;
                await img.decode();
                
                if (parsedW.width < img.naturalWidth) {
                    // newHeight : parsedW.width = img.naturalHeight : img.naturalWidth
                    newHeight = (parsedW.width * img.naturalHeight) / img.naturalWidth;
                } else {
                    parsedW.width = img.naturalWidth;
                    newHeight = img.naturalHeight;
                }
            } catch (e) {
                console.log(e, " Cannot load " + parsedW.dataUrl);
            }

            let widget: Widget = {
                id: parsedW.id,
                startingPosX: scaleDown(originalBackgroundWidth, parsedW.startingPosX, window.innerWidth),
                startingPosY: scaleDown(originalBackgroundHeight, parsedW.startingPosY, window.innerWidth / (currentScreenRatio)),
                posX: scaleDown(originalBackgroundWidth, parsedW.posX, window.innerWidth),
                posY: scaleDown(originalBackgroundHeight, parsedW.posY, window.innerWidth / (currentScreenRatio)),
                startingWidth: scaleDown(originalBackgroundWidth, parsedW.width, window.innerWidth),
                startingHeight: scaleDown(originalBackgroundHeight, newHeight, window.innerWidth / (currentScreenRatio)),
                width: scaleDown(originalBackgroundWidth, parsedW.width, window.innerWidth),
                height: scaleDown(originalBackgroundHeight, newHeight, window.innerWidth / (currentScreenRatio)),
                visible: true,
                originalVisibility: true,
                dataUrl: (parsedW.dataUrl && parsedW.dataUrl.startsWith("http")) ? parsedW.dataUrl : config.mediaEndpoint + parsedW.dataUrl,
                dataRaw: parsedW.dataRaw,
                dataBlob: (downloadedBlob != null && downloadedBlob.length > 0) ? downloadedBlob[0] : parsedW.dataBlob,
                dataName: parsedW.dataName,
                dataType: parsedW.dataType,
                loadedFromCache: downloadedBlob != null && downloadedBlob.length > 0,
                type: parsedW.type,
            };
            localWidgets.push(widget);
        }

        for (let i = 0; jsonBody['audios'] != null && i < jsonBody['audios'].length; i++) {
            let parsedA = jsonBody['audios'][i];
            let downloadedBlob = downloadedMediaItems.filter((item) => item.url == parsedA.dataUrl);

            let widget: Widget = {
                id: parsedA.id,
                startingPosX: 0,
                startingPosY: 0,
                posX: 0,
                posY: 0,
                startingWidth: 0,
                startingHeight: 0,
                width: 0,
                height: 0,
                visible: true,
                originalVisibility: true,
                dataUrl: (parsedA.dataUrl && parsedA.dataUrl.startsWith("http")) ? parsedA.dataUrl : config.mediaEndpoint + parsedA.dataUrl,
                dataRaw: parsedA.dataRaw,
                dataBlob: (downloadedBlob != null && downloadedBlob.length > 0) ? URL.createObjectURL(downloadedBlob[0].data) : URL.createObjectURL(await fetch(parsedA.dataUrl).then(r => r.blob())),
                dataName: parsedA.dataName,
                dataType: parsedA.dataType,
                loadedFromCache: downloadedBlob != null && downloadedBlob.length > 0,
                type: parsedA.type,
            };
            localAudios.push(widget);
        }

        for (let i = 0; i < jsonBody['actions'].length; i++) {
            let parsedW = jsonBody['actions'][i];

            let action: Action = {
                id: parsedW.id,
                widget1: parsedW.widget1,
                widget2: parsedW.widget2,
                actionType: parsedW.actionType
            };

            localActions.push(action);
        }

        /* enforce visibility */
        for (let i = 0; i < localWidgets.length; i++) {
            for (let j = 0; j < localActions.length; j++) {
                if (localActions[j].widget2 == localWidgets[i].id &&
                    (localActions[j].actionType == ActionType.show ||
                        localActions[j].actionType == ActionType.showUnique
                    ))
                    localWidgets[i].visible = false;
            }
        }

        setScreenSize([window.innerWidth, window.innerWidth / (currentScreenRatio)]);
        setWidgets(localWidgets);
        setAudios(localAudios);
        setActions(localActions);
        setIsLoading(false)
    }

    const onUploadProgress = (progress) => {
        setProgress(+Number(progress).toFixed());
    }

    const getCircularReplacer = () => {
        const seen = new WeakSet();

        return (key, value) => {
            if (key == "ref")
                return;
            if (key == "dataBlob")
                return;
            if (typeof value === "object" && value !== null) {
                if (seen.has(value)) {
                    return;
                }

                seen.add(value);
            }

            return value;
        };
    };

    const save = async (data: any) => {

        /* Background */
        if (background !== null && background.dataUrl === undefined) {
            const formData = new FormData();
            formData.append('file', background.dataRaw);
            formData.append('name', background.dataName);

            setProgress(0);

            let resp = await auth.uploadMedia(formData, onUploadProgress);
            background.dataUrl = config.mediaEndpoint + resp.data.url;
        }

        /* Widgets */
        for (let i = 0; i < widgets.length; i++) {
            if (widgets[i].dataUrl !== undefined)
                continue;

            if (widgets[i].type === WidgetType.canvas)
                continue;

            setProgress(0);

            const formData = new FormData();
            formData.append('file', widgets[i].dataRaw);
            formData.append('name', widgets[i].dataName);

            let resp = await auth.uploadMedia(formData, onUploadProgress);
            widgets[i].dataUrl = config.mediaEndpoint + resp.data.url;
        }
        let remoteWidgets = [...widgets];
        for (let i = 0; i < remoteWidgets.length; i++) {
            remoteWidgets[i].ref = undefined;
            remoteWidgets[i].dataBlob = undefined;
        }

        /* Audios */
        for (let i = 0; i < audios.length; i++) {
            if (audios[i].dataUrl !== undefined)
                continue;

            setProgress(0);

            const formData = new FormData();
            formData.append('file', audios[i].dataRaw);
            formData.append('name', audios[i].dataName);

            let resp = await auth.uploadMedia(formData, onUploadProgress);
            audios[i].dataUrl = config.mediaEndpoint + resp.data.url;
        }
        let remoteAudios = [...audios];
        for (let i = 0; i < remoteAudios.length; i++) {
            remoteAudios[i].ref = undefined;
            remoteAudios[i].dataBlob = undefined;
        }

        data['content'] = JSON.stringify({ "data": { 'ratio': screenRatioStr, 'title': title, 'instructions': instructions, 'background': background, 'widgets': remoteWidgets, 'audios': remoteAudios, 'actions': actions } }, getCircularReplacer());
        data['type'] = 1; // Interactive
        data['screen_ratio'] = screenRatioStr;

        if (params.id !== "new")
            await auth.editLesson(params.id, data);
        else
            await auth.addLesson(data);

        setSaveShown(false);
        setProgress(0);
    }

    const onPlay = () => {
        if (vidRef && vidRef.current)
            vidRef.current.play();
    }

    const onPause = () => {
        if (vidRef && vidRef.current)
            vidRef.current.pause();
    }

    const onBack = () => {
        if (vidRef && vidRef.current)
            vidRef.current.currentTime = vidRef.current.currentTime - 10;
    }

    const onForward = () => {
        if (vidRef && vidRef.current)
            vidRef.current.currentTime = vidRef.current.currentTime + 10;
    }

    const showContextMenu = (event, widget: Widget) => {
        event.preventDefault();

        if (contextMenuVisible) {
            setContextMenuVisible(false);
            return;
        }
        setContextMenuVisible(false);
        const positionChange = {
            x: event.pageX,
            y: event.pageY,
        };
        setContextMenuPosition(positionChange);
        setContextMenuVisible(true);
        setContextMenuObject(widget);
    };

    const changeDimension = (data) => {

        setWidgets(widgets.map(item =>
            item.id === contextMenuObject.id
                ? {
                    ...item,
                    width: data.width,
                    height: (data.width * item.height) / item.width,
                    posX: data.posX,
                    posY: data.posY,
                    startingPosX: data.posX,
                    startingPosY: data.posY,
                }
                : item));

        let widget = lookupWidget(widgets, contextMenuObject.id)

        widget.ref.updatePosition({
            x: +data.posX,
            y: +data.posY
        });
        widget.ref.updateSize({
            width: +data.width,
            height: +data.height
        });

        setChangeDimensionShown(false);
        setContextMenuObject(null);
        setContextMenuVisible(false);
    }

    const changeTitle = (data) => {
        setTitle(data.title);
        setInstructions(data.instructions);

        setChangeTitleShown(false);
    }

    const duplicateWidget = () => {
        let newWidget = Object.assign({}, contextMenuObject);
        newWidget.id = Date.now().toString();
        setWidgets([...widgets, newWidget]);
        setContextMenuObject(null);
        setContextMenuVisible(false);
    }

    const deleteWidget = () => {
        let localWidgets = widgets.filter(obj => obj.id !== contextMenuObject.id);
        setWidgets(localWidgets);
        setContextMenuObject(null);
        setContextMenuVisible(false);
    }

    const deleteActions = () => {
        let myWidget = contextMenuObject;
        let localWidgets = widgets;
        let localActions = actions.filter(obj => (obj.widget1 !== myWidget.id && obj.widget2 !== myWidget.id));

        for (let i = 0; i < localWidgets.length; i++) {
            localWidgets[i].visible = true;
        }

        /* enforce visibility */
        for (let i = 0; i < localWidgets.length; i++) {
            for (let j = 0; j < localActions.length; j++) {
                if (localActions[j].widget2 == localWidgets[i].id && localActions[j].actionType == ActionType.show)
                    localWidgets[i].visible = false;
            }
        }

        setWidgets(localWidgets);
        setActions(localActions);
        setContextMenuObject(null);
        setContextMenuVisible(false);
    }

    const resizeAllObjectText = () => {
        let localWidgets = widgets;
        let maxWidth = 0;
        let maxHeight = 0;

        for (let i = 0; i < localWidgets.length; i++) {
            if (localWidgets[i].type !== WidgetType.objectText && localWidgets[i].type !== WidgetType.objectDestination)
                continue;

            if (localWidgets[i].width > maxWidth)
                maxWidth = localWidgets[i].width;

            if (localWidgets[i].height > maxHeight)
                maxHeight = localWidgets[i].height;
        }

        for (let i = 0; i < localWidgets.length; i++) {
            if (localWidgets[i].type !== WidgetType.objectText && localWidgets[i].type !== WidgetType.objectDestination)
                continue;

            localWidgets[i].width = maxWidth;
            localWidgets[i].height = maxHeight;

            localWidgets[i].ref.updateSize({
                width: maxWidth,
                height: maxHeight
            });
        }

        setWidgets(localWidgets);
    }

    const createDestinationObject = () => {
        let widget: Widget = {
            id: Date.now().toString(),
            startingPosX: 0,
            startingPosY: 0,
            posX: 0,
            posY: 0,
            startingWidth: screenRef.current.offsetWidth,
            startingHeight: screenRef.current.offsetHeight,
            width: contextMenuObject.width,
            height: contextMenuObject.height,
            visible: true,
            originalVisibility: true,
            type: WidgetType.objectDestination,
            dataType: DataType.text,
            dataName: contextMenuObject.dataName,
            dataRaw: contextMenuObject.dataRaw,
            dataBlob: contextMenuObject.dataBlob,
            dataUrl: contextMenuObject.dataUrl
        };
        setWidgets([...widgets, widget]);

        setContextMenuObject(null);
        setContextMenuVisible(false);
    }

    const onSelectRatio = (data) => {
        if (data['ratio'] == "16_9") {
            setScreenRatioStr("16_9");
            setScreenRatio(16/9);
        }

        setShowSelectRatio(false);
    }

    if (isLoading)
        return <div className='grid h-screen place-items-center'><HashLoader color="#EF7d00" /></div>

    if (isError)
        return <div className='grid h-screen place-items-center'>{t('cannotLoadPage')}</div>

    if (showSelectRatio)
        return <div className="w-full flex flex-col">
            <Popup open={true}
                position="right center"
                closeOnDocumentClick
            >
                <div className="flex flex-col items-center gap-6 p-6">
                    <span>{t('select')} {t('screenRatio')}</span>
                    <div className="flex flex-row items-center gap-6">
                        <form onSubmit={handleSubmitRatio(onSelectRatio)} className="w-full flex flex-col items-center gap-6">
                            <div className="flex flex-col">
                                <select className="bg-primary text-white rounded-full p-2 cursor-pointer" {...registerRatio("ratio", { required: true })}>
                                    <option value="48_9">Miri</option>
                                    <option value="16_9">Pinguin</option>
                                </select>
                                {errors.ratioRequired && <span>{t('fieldMandatory')}</span>}
                            </div>
                            <div className="flex flex-row gap-3">
                                <button className="bg-primary text-white font-bold py-2 px-4 rounded-2xl" type="submit">{t('ok')}</button>
                                <Link to={auth.isAdmin() ? "/admin/lessons/" : "/user/personal-area"} className="bg-primary text-white font-bold py-2 px-4 rounded-2xl border-2 border-white">{t('back')}</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </Popup>
        </div>

    return (
        <div className="w-full h-screen flex flex-col">
            {(composerMode === ComposerMode.edit || composerMode === ComposerMode.audioList)? (
                <>
                    <div>
                        <Popup open={addActionShown}
                            position="right center"
                            closeOnDocumentClick
                        >
                            <div className="flex flex-col items-center gap-6 p-6">
                                <span>{t('selectActionType')}</span>
                                <div className="flex flex-row items-center gap-6">
                                    <button onClick={() => selectAction(ActionType.show)} className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">{t('showBaloon')}</button>
                                    <button onClick={() => selectAction(ActionType.showUnique)} className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">{t('showUniqueBaloon')}</button>
                                    <button onClick={() => selectAction(ActionType.match)} className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">{t('dragAndDrop')}</button>
                                    <button onClick={() => selectAction(ActionType.playAudio)} className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">{t('playAudio')}</button>
                                    <button onClick={() => selectAction(ActionType.truefalse)} className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">{t('trueFalse')}</button>
                                </div>
                            </div>
                        </Popup>

                        <Popup open={changeTitleShown}
                            position="right center"
                            closeOnDocumentClick
                        >
                            <div className="flex flex-col items-center gap-6 p-6">
                                <form onSubmit={handleSubmitTitle(changeTitle)} className="w-full flex flex-col items-center gap-6">
                                    <div className="flex flex-col">
                                        <label className="block text-gray-700 text-sm font-bold mb-2">{t('title')}</label>
                                        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...registerTitle("title", { })} />
                                    </div>
                                    <div className="flex flex-col">
                                        <label className="block text-gray-700 text-sm font-bold mb-2">{t('instructions')}</label>
                                        <textarea className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...registerTitle("instructions", { })} />
                                    </div>

                                    <button className="bg-primary text-white font-bold py-2 px-4 rounded-full" type="submit">{t('save')}</button>
                                    <button onClick={(e) => { e.preventDefault(); setChangeTitleShown(false) }} className="border-2 border-primary text-primary font-bold py-2 px-4 rounded-full">{t('cancel')}</button>
                                </form>
                            </div>
                        </Popup>

                        <Popup open={videoWarningShown}
                            position="right center"
                            closeOnDocumentClick
                        >
                            <div className="flex flex-col items-start p-6">
                                <span>{t('videoWarningText')}</span>
            					<div className="flex flex-row justify-end p-6 gap-3">
						            <button className='border-2 border-primary rounded-xl p-2' onClick={() => setVideoWarningShown(false)}>{t('ok')}</button>
					            </div>
                            </div>
                        </Popup>

                        <Popup open={changeDimensionShown}
                            position="right center"
                            closeOnDocumentClick
                        >
                            <div className="flex flex-col items-center gap-6 p-6">
                                <span>{t('selectDimensions')}</span>
                                <div className="flex flex-row items-center gap-6">
                                    <form onSubmit={handleSubmitDim(changeDimension)} className="w-full flex flex-col items-center gap-6">
                                        <div className="flex flex-col">
                                            <label className="block text-gray-700 text-sm font-bold mb-2">{t('width')}</label>
                                            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...registerDim("width", { required: true })} />
                                            {errors.widthRequired && <span>{t('fieldMandatory')}</span>}
                                        </div>
                                        <div className="flex flex-col">
                                            <label className="block text-gray-700 text-sm font-bold mb-2">X</label>
                                            <textarea className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...registerDim("posX", { required: true })} />
                                            {errors.posXRequired && <span>{t('fieldMandatory')}</span>}
                                        </div>
                                        <div className="flex flex-col">
                                            <label className="block text-gray-700 text-sm font-bold mb-2">Y</label>
                                            <textarea className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...registerDim("posY", { required: true })} />
                                            {errors.posYRequired && <span>{t('fieldMandatory')}</span>}
                                        </div>

                                        <button className="bg-primary text-white font-bold py-2 px-4 rounded-full" type="submit">{t('save')}</button>
                                        <button onClick={(e) => { e.preventDefault(); setContextMenuObject(null); setContextMenuVisible(false); setChangeDimensionShown(false) }} className="border-2 border-primary text-primary font-bold py-2 px-4 rounded-full">{t('cancel')}</button>
                                    </form>
                                </div>
                            </div>
                        </Popup>

                        <Popup open={addExternalVideoShown}
                            position="right center"
                            closeOnDocumentClick
                        >
                            <div className="flex flex-col items-center p-6">
                                <div className="flex flex-row items-center">
                                    <form onSubmit={handleSubmitExtVid(addExternalVideo)} className="w-full flex flex-col items-center">
                                        <div className="flex flex-col">
                                            <label className="block text-gray-700 text-sm font-bold mb-2">{t('insertLink')}</label>
                                            <textarea className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...registerExtVid("body", { required: true })} />
                                            {errors.body && <span>{t('fieldMandatory')}</span>}
                                        </div>

                                        <button className="bg-primary text-white font-bold py-2 px-4 rounded-full" type="submit">{t('save')}</button>
                                        <button onClick={(e) => { e.preventDefault(); setAddExternalVideoShown(false) }} className="border-2 border-primary text-primary font-bold py-2 px-4 rounded-full">{t('cancel')}</button>
                                    </form>
                                </div>
                            </div>
                        </Popup>

                        <Popup open={addTextShown}
                            position="right center"
                            closeOnDocumentClick
                        >
                            <div className="flex flex-col items-center p-6">
                                <div className="flex flex-row items-center">
                                    <form onSubmit={handleSubmitText(doAddText)} className="w-full flex flex-col items-center">
                                        <div className="flex flex-col mb-2">
                                            <label className="block text-gray-700 text-sm font-bold mb-2">{t('insertText')}</label>
                                            <textarea rows={5} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...registerText("body", { required: true })} />
                                            {errors.body && <span>{t('fieldMandatory')}</span>}
                                        </div>
                                        <div className="flex flex-row gap-2">
                                            <button className="bg-primary text-white font-bold py-2 px-4 rounded-full" type="submit">{t('save')}</button>
                                            <button onClick={(e) => { e.preventDefault(); setAddTextShown(false) }} className="border-2 border-primary text-primary font-bold py-2 px-4 rounded-full">{t('cancel')}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Popup>

                        <Popup open={saveShown}
                            position="right center"
                            closeOnDocumentClick
                        >
                            <div className="flex flex-col items-center gap-6 p-6">
                                <span>{t('insertLessonData')}</span>
                                <form onSubmit={handleSubmit(save)} className="w-full flex flex-col items-center gap-6">
                                    <div className="flex flex-col">
                                        <label className="block text-gray-700 text-sm font-bold mb-2">{t('internalName')}</label>
                                        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...register("name", { required: true })} />
                                        {errors.name && isSubmitted && <span className="text-red">{t('fieldMandatory')}</span>}
                                    </div>
                                    <div className="flex flex-col">
                                        <label className="block text-gray-700 text-sm font-bold mb-2">{t('title')}</label>
                                        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...register("title", { required: true })} />
                                        {errors.title && isSubmitted && <span className="text-red">{t('fieldMandatory')}</span>}
                                    </div>
                                    <div className="flex flex-col">
                                        <label className="block text-gray-700 text-sm font-bold mb-2">{t('description')}</label>
                                        <textarea className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...register("description", {})} />
                                    </div>
                                    { auth.isAdmin() &&
                                        <div className="flex flex-col">
                                            <select {...register("fk_country", {})} className="p-4 mx-2 cursor-pointer">
                                                {countries?.map(({ id, name }, index) => <option value={id} >{name}</option>)}
                                            </select>
                                        </div>
                                    }
                                    <div className="flex flex-col">
                                        <label className="block text-gray-700 text-sm font-bold mb-2">{t('completed')}?</label>
                                        <input type="checkbox" {...register("is_completed")} />
                                    </div>
                                    {progress > 0 && (
                                        <div className="w-full">
                                            <ProgressBar completed={progress} maxCompleted={100} />
                                        </div>
                                    )}
                                    <button className="bg-primary text-white font-bold py-2 px-4 rounded-full" type="submit">{t('save')}</button>
                                    <button onClick={(e) => { e.preventDefault(); setSaveShown(false) }} className="border-2 border-primary text-primary font-bold py-2 px-4 rounded-full">{t('cancel')}</button>
                                </form>
                            </div>
                        </Popup>

                        <input type="file" className="hidden" onChange={onSelectBackground} ref={selectBackgroundRef} />
                        <input type="file" className="hidden" onChange={onSelectObject} ref={selectObjectRef} multiple={true} />
                        <input type="file" className="hidden" onChange={onSelectAudio} ref={selectAudioRef} />

                        <div className={`w-full ${screenRatioStr == "48_9" ? "aspect-[48/9]" : "aspect-[16/9]"} relative overflow-x-hidden`} ref={screenRef}>
                            <div className="w-1/3 absolute left-1/2 transform -translate-x-1/2 flex flex-col items-center">
                                { title.length > 0 ? <span className="w-full text-center bg-primary p-2 text-white rounded-b-xl text-[0.5vw]">{title}</span> : <span></span> }
                                { instructions.length > 0 ? <span className="w-4/5 text-center bg-white p-2 text-black rounded-b-xl text-[0.5vw]">{instructions}</span> : <span></span> }
                            </div>
                            { screenRatioStr == "48_9" && 
                                <div className="absolute w-full h-full flex flex-row">
                                    <div className="w-1/3 h-full border-2 border-primary border-dashed"></div>
                                    <div className="w-1/3 h-full border-y-2 border-primary border-dashed"></div>
                                    <div className="w-1/3 h-full border-2 border-primary border-dashed"></div>
                                </div>
                            }
                            {background && background.dataType === DataType.externalVideo && background.type === WidgetType.background && (
                                (<iframe className="w-full h-full object-fill" src={background.dataUrl}></iframe>)
                            )}
                            {background && background.dataType === DataType.image && background.type === WidgetType.background && (
                                (<img className="w-full h-full object-fill" src={(background.dataUrl && !background.loadedFromCache) ? background.dataUrl : background.dataBlob} />)
                            )}
                            {background && background.dataType === DataType.video && background.type === WidgetType.background && (
                                <video className="w-full h-full object-fill" autoPlay loop ref={vidRef}>
                                    <source src={(background.dataUrl && !background.loadedFromCache) ? background.dataUrl : background.dataBlob} type="video/mp4" />
                                </video>
                            )}
                            
                            {
                                widgets.map((widget) =>
                                    <Rnd ref={ref => {
                                        widget.ref = ref;
                                    }}
                                        key={widget.id}
                                        enableResizing={true}
                                        onDragStop={(e, d) => { onDragStop(widget.id, d) }}
                                        onResizeStop={(e, direction, ref, delta, position) => { onResizeStop(widget.id, ref.style) }}
                                        default={{
                                            x: widget.posX,
                                            y: widget.posY,
                                            width: widget.width,
                                            height: widget.height,
                                        }}
                                        onContextMenu={(event) => showContextMenu(event, widget)}
                                    >
                                        {widget.type == WidgetType.object ? (
                                            <img className="outline outline-1 outline-primary" draggable={false} src={widget.dataUrl ? widget.dataUrl : widget.dataBlob} onClick={() => clicked(widget.id)} />
                                        ) : widget.type == WidgetType.objectText ? (
                                            <div className={`w-full h-full bg-white flex-shrink-0 outline outline-2 outline-primary p-2 rounded-3xl text-center text-[0.5vw] flex flex-col justify-center`} onClick={() => clicked(widget.id)}><span>{widget.dataRaw}</span></div>
                                        ) : widget.type == WidgetType.objectDestination ?
                                            <div className={`w-full h-full bg-white flex-shrink-0 outline outline-2 outline-primary p-2 rounded-3xl text-center text-[0.5vw] text-white`} onClick={() => clicked(widget.id)}><span>{widget.dataRaw}</span></div>
                                        : (
                                            <div className="flex flex-row justify-center rounded-2xl bg-primary opacity-50 items-center border-4 border-primary w-full h-full flex-shrink-0 text-white" onClick={() => clicked(widget.id)}>
                                                <span className="text-2xl">{t('whiteboard')}</span>
                                            </div>
                                        )}
                                    </Rnd>
                                )}
                            {statusText && statusText.length > 0 && (
                                <div className="absolute top-2 right-32 justify-center rounded-2xl bg-primary flex-shrink-0 text-white p-2">
                                    {statusText}
                                </div>
                            )}
                            {contextMenuVisible && (
                                <div
                                    style={{ top: contextMenuPosition.y, left: contextMenuPosition.x }}
                                    className="bg-primary rounded-2xl text-white p-2 gap-2 flex flex-col justify-center absolute"
                                >
                                    <div className="cursor-pointer text-xs hover:underline" onClick={() => duplicateWidget()}>
                                        {t('duplicate')}
                                    </div>
                                    <div className="cursor-pointer text-xs hover:underline" onClick={() => {
                                        setValueDim('width', Math.round(contextMenuObject.width));
                                        setValueDim('height', Math.round(contextMenuObject.height));
                                        setValueDim('posX', Math.round(contextMenuObject.posX));
                                        setValueDim('posY', Math.round(contextMenuObject.posY));
                                        setChangeDimensionShown(true);
                                    }}
                                    >
                                        {t('changeDimensions')}
                                    </div>
                                    <div className="cursor-pointer text-xs hover:underline" onClick={() => deleteWidget()}>
                                        {t('deleteObject')}
                                    </div>
                                    <div className="cursor-pointer text-xs hover:underline" onClick={() => deleteActions()}>
                                        {t('deleteAssociatedActions')}
                                    </div>
                                    { contextMenuObject.type == WidgetType.objectText && 
                                        <div className="cursor-pointer text-xs hover:underline" onClick={() => createDestinationObject()}>
                                            {t('createDestinationObject')}
                                        </div>
                                    }
                                </div>
                            )}
                            <ControlBar
                                onReset={() => resetPositions()}
                                onPlay={() => onPlay()}
                                onPause={() => onPause()}
                                onBack={() => onBack()}
                                onForward={() => onForward()}
                            />
                        </div>
                    </div>
                    <div className="w-full h-full bg-primary-light">
                        { composerMode == ComposerMode.edit && (
                            <div className="flex flex-row">
                                <div className="w-1/4 flex flex-col items-center">
                                    <div className="w-1/2 bg-gradient-to-r from-20% from-g-primary-light to-g-primary-dark text-white text-center font-bold py-2 px-4 rounded-b-2xl border-r-2 border-l-2 border-b-2 border-white">{t('embed')}</div>
                                    <div className="w-1/2 flex flex-row items-center justify-between mt-6">
                                        <img className="w-12 cursor-pointer" onClick={() => setAddExternalVideoShown(true)} src="/images/logo-canva.png" />
                                        <img className="w-12 cursor-pointer" onClick={() => setAddExternalVideoShown(true)} src="/images/logo-yt.png" />
                                        <img className="w-12 cursor-pointer" onClick={() => setAddExternalVideoShown(true)} src="/images/logo-vimeo.png" />
                                    </div>
                                    <div className="w-1/2 flex flex-row items-center justify-evenly mt-6">
                                        <img className="w-12 cursor-pointer" onClick={() => setAddExternalVideoShown(true)} src="/images/logo-genially.png" />
                                        <img className="w-12 cursor-pointer" onClick={() => setAddExternalVideoShown(true)} src="/images/logo_thinglink.png" />
                                    </div>
                                </div>
                                <div className="w-1/2 flex flex-col">
                                    <div className="w-full bg-gradient-to-r from-20% from-g-primary-light to-g-primary-dark text-black text-center font-bold py-2 px-4 rounded-b-2xl border-r-2 border-l-2 border-b-2 border-white">{t('composer')}</div>
                                    <div className="w-full flex flex-row justify-center">
                                        <div className="w-1/2 flex flex-col justify-start items-center">
                                            <div className="w-5/6 bg-gradient-to-r from-20% from-g-primary-light to-g-primary-dark text-center text-white font-bold py-2 px-4 rounded-b-2xl border-r-2 border-l-2 border-b-2 border-white">
                                                {t('actions')}
                                            </div>
                                            <div className="w-5/6 flex flex-row justify-between mt-6">
                                                <button className="bg-gradient-to-r from-20% from-g-primary-light to-g-primary-dark text-white font-bold py-2 px-4 rounded-2xl border-2 border-white" onClick={addBackground}>{t('insertBackground')}</button>
                                                <button className="bg-gradient-to-r from-20% from-g-primary-light to-g-primary-dark text-white font-bold py-2 px-4 rounded-2xl border-2 border-white" onClick={addObject}>{t('insertObject')}</button>
                                                <button className="bg-gradient-to-r from-20% from-g-primary-light to-g-primary-dark text-white font-bold py-2 px-4 rounded-2xl border-2 border-white" onClick={addAudio}>{t('insertAudio')}</button>
                                            </div>
                                            <div className="w-5/6 flex flex-row justify-evenly mt-6">
                                                <button className="bg-gradient-to-r from-20% from-g-primary-light to-g-primary-dark text-white font-bold py-2 px-4 rounded-2xl border-2 border-white" onClick={addText}>{t('insertText')}</button>
                                                <button className="bg-gradient-to-r from-20% from-g-primary-light to-g-primary-dark text-white font-bold py-2 px-4 rounded-2xl border-2 border-white" onClick={addTitle}>{t('insertTitle')}</button>
                                                <button className="bg-gradient-to-r from-20% from-g-primary-light to-g-primary-dark text-white font-bold py-2 px-4 rounded-2xl border-2 border-white" onClick={() => resizeAllObjectText()}>{t('resizeAllObjects')}</button>
                                            </div>
                                        </div>
                                        <div className="w-1/2 flex flex-col justify-center items-center">
                                            <div className="w-5/6 bg-gradient-to-r from-20% from-g-primary-light to-g-primary-dark text-center text-white font-bold py-2 px-4 rounded-b-2xl border-r-2 border-l-2 border-b-2 border-white">
                                                {t('activity')}
                                            </div>
                                            <div className="w-5/6 flex flex-row justify-between mt-6">
                                                <button className="bg-gradient-to-r from-20% from-g-primary-light to-g-primary-dark text-white font-bold py-2 px-4 rounded-2xl border-2 border-white" onClick={() => selectAction(ActionType.match)}>{t('dragAndDrop')}</button>
                                                <button className="bg-gradient-to-r from-20% from-g-primary-light to-g-primary-dark text-white font-bold py-2 px-4 rounded-2xl border-2 border-white" onClick={() => selectAction(ActionType.show)}>{t('boxDiscoverUnique')}</button>
                                                <button className="bg-gradient-to-r from-20% from-g-primary-light to-g-primary-dark text-white font-bold py-2 px-4 rounded-2xl border-2 border-white" onClick={() => selectAction(ActionType.show)}>{t('boxDiscoverMultiple')}</button>
                                            </div>
                                            <div className="w-5/6 flex flex-row justify-between mt-3">
                                                <button className="bg-gradient-to-r from-20% from-g-primary-light to-g-primary-dark text-white font-bold py-2 px-4 rounded-2xl border-2 border-white" onClick={addCanvas}>{t('blackboard')}</button>
                                                <button className="bg-gradient-to-r from-20% from-g-primary-light to-g-primary-dark text-white font-bold py-2 px-4 rounded-2xl border-2 border-white" onClick={() => selectAction(ActionType.truefalse)}>{t('trueFalse')}</button>
                                                <button className="bg-gradient-to-r from-20% from-g-primary-light to-g-primary-dark text-white font-bold py-2 px-4 rounded-2xl border-2 border-white" onClick={() => selectAction(ActionType.playAudio)}>{t('playSound')}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-1/4 flex flex-col items-center">
                                    <div className="w-1/2 flex flex-row my-4 gap-2">
                                        <button className="bg-gradient-to-r from-20% from-g-primary-light to-g-primary-dark text-white font-bold py-2 px-4 rounded-2xl border-2 border-white" onClick={changeView}>{t('preview')}</button>
                                        <button className="w-full flex flex-row justify-center items-center bg-white text-primary font-bold py-2 px-4 rounded-2xl border-2 border-primary" onClick={() => setSaveShown(true)}><FaSave /></button>
                                        <Link to={auth.isAdmin() ? "/admin/lessons/" : "/user/personal-area"} className="bg-gradient-to-r from-20% from-g-primary-light to-g-primary-dark text-white font-bold py-2 px-4 rounded-2xl border-2 border-white">{t('quit')}</Link>
                                    </div>
                                    <div>
                                    </div>
                                </div>
                            </div>
                        )}
                        { composerMode == ComposerMode.audioList && (
                            <div className="w-full flex flex-col items-center">
                                <div className="w-full bg-gradient-to-r from-20% from-g-primary-light to-g-primary-dark text-white text-center font-bold py-2 px-4 rounded-b-2xl border-r-2 border-l-2 border-b-2 border-white">{t('audio')}</div>
                                <div className="w-5/6 flex flex-row">
                                    <div className="w-5/6 mt-3">
                                        { audios.length == 0 && (
                                            <span className="text-black">{t('noAudioPresent')}</span>
                                        )}
                                        <div className="w-1/2">
                                            { halve(audios, true).map((audio) => (
                                                <div className="flex flex-row justify-between bg-gradient-to-r from-20% from-g-primary-light to-g-primary-dark text-white font-bold py-2 px-4 mt-2 rounded-2xl border-2 border-white">
                                                    <div className="flex flex-row justify-start items-center gap-3">
                                                        <FaCirclePlus className="cursor-pointer" onClick={() => clickedAudio(audio.id)} />
                                                        <p className="text-gray-200 text-sm font-bold truncate">{audio.dataName}</p>
                                                    </div>
                                                    <div className="flex flex-row items-center">
                                                        <FaPlay onClick={() => playAudio(audio)} className="ml-2 cursor-pointer"/>
                                                        <FaStop onClick={stopAudio} className="ml-2 cursor-pointer"/>
                                                        <FaTrash onClick={() => deleteAudio(audio)} className="ml-2 cursor-pointer"/>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="w-1/2">
                                            { halve(audios, false).map((audio) => (
                                                <div className="flex flex-row justify-between bg-gradient-to-r from-20% from-g-primary-light to-g-primary-dark text-white font-bold py-2 px-4 mt-2 rounded-2xl border-2 border-white">
                                                    <div className="flex flex-row justify-start items-center gap-3">
                                                        <FaCirclePlus className="cursor-pointer" onClick={() => clickedAudio(audio.id)} />
                                                        <p className="text-gray-200 text-sm font-bold truncate">{audio.dataName}</p>
                                                    </div>
                                                    <div className="flex flex-row items-center">
                                                        <FaPlay onClick={() => playAudio(audio)} className="ml-2 cursor-pointer"/>
                                                        <FaStop onClick={stopAudio} className="ml-2 cursor-pointer"/>
                                                        <FaTrash onClick={() => deleteAudio(audio)} className="ml-2 c"/>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="w-1/6">
                                        <div className="w-1/2 flex flex-row gap-3">
                                            <div className="flex flex-row justify-end mt-6">
                                                <button className="w-full bg-white text-primary font-bold py-2 px-4 rounded-2xl border-2 border-primary" onClick={addAudio}>{t('add')}</button>
                                            </div>
                                            <div className="flex flex-row justify-between mt-6">
                                                <button className="bg-gradient-to-r from-20% from-g-primary-light to-g-primary-dark text-white font-bold py-2 px-4 rounded-2xl border-2 border-white" onClick={changeView}>{t('composer')}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </>
            ) : (
                <>
                    <div>
                        <div className={`w-full ${screenRatioStr == "48_9" ? "aspect-[48/9]" : "aspect-[16/9]"} relative overflow-x-hidden`} ref={screenRef}>
                            <div className="w-1/3 absolute left-1/2 transform -translate-x-1/2 flex flex-col items-center">
                                { title.length > 0 ? <span className="w-full text-center bg-primary p-2 text-white rounded-b-xl text-[0.5vw]">{title}</span> : <span></span> }
                                { instructions.length > 0 ? <span className="w-4/5 text-center bg-white p-2 text-black rounded-b-xl text-[0.5vw]">{instructions}</span> : <span></span> }
                            </div>

                            {background && background.dataType === DataType.externalVideo && background.type === WidgetType.background && (
                                (<iframe className="w-full h-full object-fill" src={background.dataUrl}></iframe>)
                            )}
                            {background && background.dataType === DataType.image && background.type === WidgetType.background && (
                                (<img className="w-full h-full object-fill" src={(background.dataUrl && !background.loadedFromCache) ? background.dataUrl : background.dataBlob} />)
                            )}
                            {background && background.dataType === DataType.video && background.type === WidgetType.background && (
                                <video className="w-full h-full object-fill" autoPlay loop ref={vidRef}>
                                    <source src={(background.dataUrl && !background.loadedFromCache) ? background.dataUrl : background.dataBlob} type="video/mp4" />
                                </video>
                            )}
                            {
                                widgets.map((widget, index) =>
                                    <Rnd
                                        ref={ref => {
                                            widget.ref = ref;
                                        }}
                                        key={widget.id}
                                        enableResizing={false}
                                        disableDragging={true}
                                        className="absolute"
                                        //onDragStop={(e, d) => performDragAction(d, widget.id)}
                                        default={{
                                            x: widget.posX,
                                            y: widget.posY,
                                            width: widget.width,
                                            height: widget.height,
                                        }}
                                    >
                                        {widget.type === WidgetType.object ? (
                                            <>
                                                <img draggable={false} className={`${widget.isFailed ? "animate-shake" : ""} ${widget.visible ? "visible" : "invisible"} ${widget.id == firstActionElement?.id ? "outline outline-1 outline-primary rounded-2xl" : ""}`} src={widget.dataUrl ? widget.dataUrl : widget.dataBlob} onMouseDown={() => performClickAction(widget.id)} />
                                                <div className={`${widget.isRight ? "opacity-100 duration-300 absolute top-1 left-1 z-10 flex justify-center items-center text-8xl text-green font-semibold" : "opacity-0 hidden"}`}> ✓</div>
                                            </>
                                        ) : widget.type == WidgetType.objectText ? (
                                            <div className={`${widget.isFailed ? "animate-shake" : ""} ${widget.visible ? "visible" : "invisible"} w-full h-full bg-white flex-shrink-0 outline outline-2 outline-primary p-2 rounded-3xl text-center text-[0.5vw] flex flex-col justify-center`} onClick={() => performClickAction(widget.id)}><span>{widget.dataRaw}</span></div>
                                        ) : widget.type == WidgetType.objectDestination ?
                                            <div className={`${widget.isFailed ? "animate-shake" : ""} ${widget.visible ? "visible" : "invisible"} w-full h-full bg-white flex-shrink-0 outline outline-2 outline-primary p-2 rounded-3xl text-center text-[0.5vw] text-white`} onClick={() => performClickAction(widget.id)}><span>{widget.dataRaw}</span></div>
                                        : (
                                            <div className="w-full h-full flex flex-col items-center">
                                                <CanvasDraw className="rounded-2xl" style={{ background: "none" }}
                                                    canvasWidth="100%"
                                                    canvasHeight="100%"
                                                    brushColor={brushColor}
                                                    hideGrid={true}
                                                    ref={ref => {
                                                        canvasRefs.current[index] = ref;
                                                    }}
                                                />
                                                <div className="mt-2 flex flex-row gap-2">
                                                    <div onClick={() => setBrushColor("rgba(255, 165, 0, 0.8)")} className={`${brushColor == 'rgba(255, 165, 0, 0.8)' ? "border-2 border-black" : ""} w-8 h-8 bg-red rounded-full cursor-pointer`}></div>
                                                    <div onClick={() => setBrushColor("rgba(135, 206, 250, 0.8)")} className={`${brushColor == 'rgba(135, 206, 250, 0.8)' ? "border-2 border-black" : ""} w-8 h-8 bg-green rounded-full cursor-pointer`}></div>
                                                    <div onClick={() => setBrushColor("rgba(0, 0, 255, 0.8)")} className={`${brushColor == 'rgba(0, 0, 255, 0.8)' ? "border-2 border-black" : ""} w-8 h-8 bg-blue rounded-full cursor-pointer`}></div>
                                                    <div onClick={() => setBrushColor("rgba(255, 255, 0, 0.8)")} className={`${brushColor == 'rgba(255, 255, 0, 0.8)' ? "border-2 border-black" : ""} w-8 h-8 bg-yellow rounded-full cursor-pointer`}></div>
                                                    <div onClick={() => setBrushColor("rgba(139, 69, 19, 0.8)")} className={`${brushColor == 'rgba(139, 69, 19, 0.8)' ? "border-2 border-black" : ""} w-8 h-8 bg-yellow rounded-full cursor-pointer`}></div>
                                                    <div onClick={() => setBrushColor("rgba(0, 0, 0, 0.8)")} className={`${brushColor == 'rgba(0, 0, 0, 0.8)' ? "border-2 border-black" : ""} w-8 h-8 bg-yellow rounded-full cursor-pointer`}></div>
                                                    <div onClick={() => setBrushColor("rgba(255, 192, 203, 0.8)")} className={`${brushColor == 'rgba(255, 192, 203, 0.8)' ? "border-2 border-black" : ""} w-8 h-8 bg-yellow rounded-full cursor-pointer`}></div>
                                                    <div onClick={() => setBrushColor("rgba(255, 0, 0, 0.8)")} className={`${brushColor == 'rgba(255, 0, 0, 0.8)' ? "border-2 border-black" : ""} w-8 h-8 bg-yellow rounded-full cursor-pointer`}></div>
                                                    <div onClick={() => setBrushColor("rgba(34, 139, 34, 0.8)")} className={`${brushColor == 'rgba(34, 139, 34, 0.8)' ? "border-2 border-black" : ""} w-8 h-8 bg-yellow rounded-full cursor-pointer`}></div>
                                                    <div onClick={() => setBrushColor("rgba(138, 43, 226, 0.8)")} className={`${brushColor == 'rgba(138, 43, 226, 0.8)' ? "border-2 border-black" : ""} w-8 h-8 bg-yellow rounded-full cursor-pointer`}></div>
                                                </div>
                                            </div>
                                        )}
                                    </Rnd>
                                )
                            }
                            <ControlBar
                                onReset={() => resetPositions()}
                                onPlay={() => onPlay()}
                                onPause={() => onPause()}
                                onBack={() => onBack()}
                                onForward={() => onForward()}
                            />
                        </div>
                    </div>
                    <div className="w-full h-full flex flex-row justify-end bg-primary-light">
                        <div className="w-1/4 flex flex-col items-center">
                            <div className="w-1/2 flex flex-col">
                                <div className="flex flex-row justify-center mt-6">
                                    <button className="bg-gradient-to-r from-20% from-g-primary-light to-g-primary-dark text-white font-bold py-2 px-4 rounded-2xl border-2 border-white" onClick={changeView}>{t('modify')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
